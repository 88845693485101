* {
  box-sizing: border-box;
}

h1 {
  overflow: hidden;
  width: 1px;
  height: 1px;
  position: absolute;
}

h2 {
  font-weight: 300;
  font-size: rem(32);
  line-height: 97%;
  letter-spacing: -0.02em;
  margin: 0 0 24px 0;

  @media (min-width: $bp-XL) {
    font-size: rem(40);
    line-height: 112%;
  }

  @media (min-width: $bp-4XL) {
    font-size: rem(56);
    line-height: 97%;
  }
}

h3,
.h3 {
  margin: 0 0 16px 0;
  font-family: $baseFontMono;
  font-style: normal;
  font-weight: 900;
  font-size: rem(16);
  line-height: 120%;
  font-feature-settings: "case" on;

  @media (min-width: $bp-4XL) {
    font-size: rem(20);
    line-height: 120%;
  }
}

p {
  font-size: rem(16);
  line-height: 120%;
  font-weight: 300;

  @media (min-width: $bp-4XL) {
    font-size: rem(20);
    line-height: rem(24);
    letter-spacing: 0;
  }
}

.link {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100%;
  text-decoration: underline;
  text-transform: uppercase;
  list-style-type: none;
  font-feature-settings: "case" on;
  letter-spacing: 2px;

  @media (min-width: $bp-4XL) {
    font-size: rem(20);
    line-height: 100%;
    letter-spacing: 3.5px;
  }
}

ul,
ol {
  padding-left: 35px;
  padding-bottom: 16px;
  margin-bottom: 0;
  font-family: $baseFontMono;
}
