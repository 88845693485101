@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport-LightItalic.woff2") format("woff2"),
    url("../fonts/Aeroport-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport-Italic.woff2") format("woff2"),
    url("../fonts/Aeroport-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport-Light.woff2") format("woff2"),
    url("../fonts/Aeroport-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport-Bold.woff2") format("woff2"),
    url("../fonts/Aeroport-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport-BoldItalic.woff2") format("woff2"),
    url("../fonts/Aeroport-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport-MediumItalic.woff2") format("woff2"),
    url("../fonts/Aeroport-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeroport Mono";
  src: url("../fonts/AeroportMono.woff2") format("woff2"),
    url("../fonts/AeroportMono.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Neue Pixel Sans";
  src: url("../fonts/NeuePixelSans.woff2") format("woff2"),
    url("../fonts/NeuePixelSans.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport-Medium.woff2") format("woff2"),
    url("../fonts/Aeroport-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeroport";
  src: url("../fonts/Aeroport.woff2") format("woff2"),
    url("../fonts/Aeroport.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
