.captcha-notice,
.captcha-notice a {
  color: #fff;
  font-weight: 300;
  font-size: 0.75rem;
}

.success-notice {
  display: none;
  color: #fff;
  border: solid 1px #fff;
  font-family: "Aeroport Mono", sans-serif;
  padding: 4px;
  margin-top: 30px !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.mobile-menu {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: $transition-ease;
  background: transparent;

  @media (min-width: $bp-XL) {
    display: none;
  }

  &__menu {
    opacity: 0;
    visibility: hidden;
    transition: $transition-ease;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
  }

  &.active {
    opacity: 1;
    position: fixed;
    background: $color-3;
    z-index: 10;

    .mobile-menu__button {
      &::before {
        background: $color-3;
      }
    }

    .mobile-menu__menu {
      opacity: 1;
      visibility: visible;
    }
  }

  &__button {
    position: fixed;
    right: 17px;
    top: 17px;
    width: 56px;
    height: 56px;
    background: none;
    border: 1px solid $color-5;
    border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    transition: $transition-ease;
    z-index: 10;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: $color-2;
      border-radius: 50%;
      transition: $transition-ease;
    }

    span {
      position: absolute;
      display: block;
      width: 32px;
      height: 1px;
      background: $color-1;
      left: 11px;
      transition: $transition-ease;

      &:nth-of-type(1) {
        top: 19px;
      }

      &:nth-of-type(2) {
        top: 26px;
      }

      &:nth-of-type(3) {
        top: 32px;
      }
    }

    &.open {
      span {
        &:nth-of-type(1) {
          top: 26px;
          transform: rotate(45deg);
          transform-origin: center;
          background: $color-2;
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          top: 26px;
          transform: rotate(-45deg);
          transform-origin: center;
          background: $color-2;
        }
      }
    }
  }

  .menu-list {
    display: flex;
    flex-direction: column;
    padding: 80px 15px 40px;
    list-style: none;
    margin: 0;

    p {
      color: $color-2;
      font-size: rem(48);
      line-height: rem(40);
      text-decoration: none;
      width: 100%;
      margin: 0;
      border-bottom: 1px solid $color-2;
      text-transform: uppercase;
      font-family: $numberFont;
      display: flex;
      flex-direction: column;
      padding: 8px 0 7px;
      cursor: pointer;
    }
  }

  .socials {
    padding: 17px 15px 24px;
    margin: auto 0 0 auto;
    display: flex;
    justify-content: flex-end;

    &__item {
      margin-left: 24px;
      width: 40px;
      height: 40px;
      overflow: hidden;
      background: $color-2;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        transition: $transition-ease;
        max-height: 25px;
      }
    }
  }
}

.hero {
  overflow: hidden;
  height: 100vh;

  @media (min-width: $bp-XL) {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    right: 50%;
    z-index: 4;
    margin-bottom: -100vh;
    overflow: hidden;
    width: 50%;
    pointer-events: none;
  }

  &__container {
    position: relative;
    padding: 15px 15px 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 768px;
    margin: 0 auto;
    z-index: 1000;

    @media (max-width: 1199px) {
      min-height: 100%;
    }

    @media (min-width: $bp-XL) {
      height: 100%;
      margin-left: auto;
      max-width: 960px;
      margin-right: 0;
    }

    @media (min-width: $bp-3XL) {
      padding: 15px 41px 27px;
    }
  }

  &__background {
    position: fixed;
    right: 50vw;
    width: 1px;
    height: 0vh;
    margin-top: 100vh;
    background: $color-3;

    @media (max-width: 1199px) {
      right: 0vw;
    }
  }

  &__counter {
    position: absolute;
    top: 0;
    left: 0;
    font-family: "Neue Pixel Sans", sans-serif;
    overflow: hidden;
    height: calc(16px + 26vw);

    @media (max-width: $bp-XL) {
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: $bp-M) {
      height: calc(16px + 20vw);
    }

    @media (min-width: $bp-XL) {
      height: calc(16px + 15vw);
    }

    @media (min-width: $bp-3XL) {
      padding-left: 41px;
    }

    @media (min-width: $bp-5XL) {
      height: 312px;
    }

    p {
      margin: 0;
      font-size: calc(16px + 26vw);
      color: $color-3;

      @media (min-width: $bp-M) {
        font-size: calc(16px + 20vw);
        line-height: calc(16px + 20vw);
      }

      @media (min-width: $bp-XL) {
        font-size: calc(16px + 15vw);
        line-height: calc(16px + 15vw);
      }

      @media (min-width: $bp-5XL) {
        font-size: 312px;
        line-height: 312px;
      }
    }
  }

  &__loading {
    position: fixed;
    top: calc(100vh - 160px);
    left: 30vw;

    div {
      overflow: hidden;
    }

    @media (min-width: $bp-M) {
      left: calc(50vw + 22px);
      top: calc(100vh - 70px);
    }

    @media (max-width: $bp-XL) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__loading-p {
    color: $color-3;
    margin: 0;
    font-size: 1.25rem;
    letter-spacing: 0.1rem;
    transform: translateY(40px);
  }

  &__content {
    margin: 0 auto;
  }

  &__logo {
    color: $color-2;
    font-family: $numberFont;
    font-size: calc(16px + 26vw);
    padding: 0;
    margin: 0 auto;
    opacity: 0;
    display: flex;
    overflow: hidden;

    span {
      display: inline-block;
      transform: translate(0px, 300px);
    }

    @media (min-width: $bp-M) {
      font-size: calc(16px + 20vw);
    }

    @media (min-width: $bp-XL) {
      font-size: calc(16px + 15vw);
      margin-top: -43px;
    }

    @media (min-width: $bp-4XL) {
      line-height: 120%;
    }

    @media (min-width: $bp-5XL) {
      font-size: 312px;
      margin-top: -69px;
    }
  }

  &__list {
    margin: 50px 0 0 72px;
    padding: 0 0 0;
    position: relative;

    &--line {
      position: absolute;
      content: "";
      left: 4px;
      // bottom: 1px;
      bottom: -265px;
      width: 1px;
      height: 265px;
      background-color: $color-2;

      @media (min-width: $bp-XL) {
        bottom: -84px;
        height: 340px;
      }

      @media (min-width: $bp-3XL) {
        height: 810px;
        bottom: -584px;
      }
    }

    @media (min-width: $bp-XL) {
      margin-top: auto;
      margin-left: auto;
      margin-right: 41px;
      padding-bottom: 270px;
    }

    @media (min-width: $bp-3XL) {
      margin-left: auto;
      margin-right: 17px;
      padding: 0 0 240px;
      margin-top: 135px;
    }

    @media (min-width: $bp-4XL) {
      margin-top: 150px;
    }

    @media (min-width: $bp-5XL) {
      margin-top: auto;
      margin-right: 89px;
      padding: 0 0 270px;
      margin-bottom: -41px;
    }

    &--item {
      margin-bottom: 10px;
      position: relative;
      color: $color-2;
      font-style: normal;
      font-weight: 300;
      font-size: rem(14);
      line-height: 100%;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      list-style-type: none;
      font-feature-settings: "case" on;
      overflow: hidden;

      @media (min-width: $bp-XL) {
        font-size: rem(16);
      }

      @media (min-width: $bp-5XL) {
        font-size: rem(20);
        line-height: 100%;
        margin-bottom: 3px;
      }

      > span {
        display: inline-block;
      }

      > span:nth-child(1) {
        display: inline-block;
        transform: translateY(20px);
      }

      span {
        color: $color-2;
        font-family: $baseFontMono;
        pointer-events: all;
        // transform: translate(0px, 40px);

        &.link-scroll {
          // text-decoration: underline;
          // margin-left: 32px;
          // transform: translate(0px, 0px);
          transform: translate(0px, 40px);
          display: inline-block;

          // span {
          //   transform: translate(0px, 0px);
          // }
        }
      }

      div {
        &.link-line {
          height: 1px;
          width: 0%;
          background-color: white;
        }
      }

      // &:before {
      //   content: counter(list-item) "\A0";
      //   float: left;
      //   text-align: left;
      //   width: 20px;

      //   @media (min-width: $bp-5XL) {
      //     padding-right: 25px;
      //   }
      // }
    }

    &--link {
      margin-left: 36px;
      overflow: hidden;
      cursor: pointer;
    }

    ::marker {
      width: 10px;
    }
  }

  &__info {
    color: $color-2;
    font-family: $baseFontMono;
    margin-top: auto;
    position: relative;
    z-index: 2;

    opacity: 0;
    transform: translate(0px, 40px);

    &::after {
      position: absolute;
      content: "";
      top: -10px;
      height: 600%;
      right: 0;
      bottom: 100%;
      left: 0;
      background: $color-3;
      z-index: -1;
    }

    @media (min-width: $bp-XL) {
      bottom: 50px;
      padding-right: 43%;
      text-indent: -1px;
      padding-bottom: 1px;
      margin-left: 34px;
      position: absolute;
      margin-top: 0;

      &::after {
        display: none;
      }
    }

    @media (min-width: $bp-3XL) {
      text-indent: 22px;
      padding-bottom: 1px;
      margin-left: 8px;
      bottom: 54px;
      padding-right: 40%;
    }

    @media (min-width: $bp-5XL) {
      padding-right: 43%;
      text-indent: 121px;
      padding-bottom: 0;
      margin-bottom: 10px;
      position: relative;
      margin-left: 0;
    }

    &:first-letter {
      margin-left: 72px;
    }
  }

  &__bottom {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;

    opacity: 0;
    transform: translate(0px, 40px);

    @media (min-width: $bp-XL) {
      margin-left: 34px;
      justify-content: flex-start;
      position: absolute;
      bottom: 19px;
      pointer-events: all;
    }

    @media (min-width: $bp-3XL) {
      margin-left: 8px;
      bottom: 25px;
    }

    @media (min-width: $bp-5XL) {
      position: relative;
      margin-left: 0;
    }

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 2px;
      width: 13px;
      height: 13px;
      background-image: url("../images/arrow-down.svg");
      background-size: cover;

      @media (min-width: $bp-3XL) {
        width: 19px;
        height: 19px;
      }
    }
  }

  &__anchor {
    color: $color-2;
    cursor: pointer;
    text-decoration: none;
    overflow: hidden;

    @media (min-width: $bp-XL) {
      margin-left: 132px;
      font-size: rem(16);
    }

    @media (min-width: $bp-5XL) {
      margin-left: 198px;
      font-size: rem(20);
    }

    span {
      &.link-scroll {
        display: inline-block;
      }
    }

    div {
      &.link-line {
        height: 1px;
        width: 0%;
        background-color: white;
      }
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  scroll-behavior: smooth;

  @media (min-width: $bp-XL) {
    align-items: flex-end;

    .scroll-content {
      width: 100%;

      & > * {
        width: 50%;
        margin-left: auto;
      }
    }
  }

  .info {
    &__container {
      padding: 87px 15px 0;
      max-width: 768px;
      margin: 0 auto;

      @media (min-width: $bp-M) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media (min-width: $bp-XL) {
        padding: 12px 40px 169px;
        max-width: 960px;
        margin: 0 auto 0 0;
      }

      @media (min-width: $bp-3XL) {
        padding: 14px 48px 169px;
      }

      @media (min-width: $bp-5XL) {
        padding: 4px 48px 169px;
      }
    }

    &__title {
      text-indent: 72px;
      margin-bottom: 88px;
      transform: translate(0px, 40px);
      opacity: 0;

      @media (min-width: $bp-XL) {
        margin-bottom: 190px;
      }

      @media (min-width: $bp-3XL) {
        text-indent: 143px;
        margin-bottom: 268px;
      }

      @media (min-width: $bp-5XL) {
        text-indent: 189px;
        margin-bottom: 301px;
      }
    }

    &__list {
      list-style-type: decimal-leading-zero;
      margin-left: 5px;
      opacity: 0;

      @media (min-width: $bp-M) {
        padding: 52px 0 0;
        margin: 0 auto;
        max-width: 600px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
      }

      ::marker {
        color: $color-3;

        @media (min-width: $bp-M) {
          display: none;
        }
      }

      li {
        padding-bottom: 20px;

        @media (min-width: $bp-M) {
          max-width: 240px;
          flex-basis: 50%;
          margin-left: 7px;
          margin-top: 40px;
          position: relative;
          padding-bottom: 24px;

          &:before {
            content: counter(list-item, decimal-leading-zero) ".";
            position: absolute;
            color: $color-3;
            left: 0;
            top: -32px;
          }
        }

        @media (min-width: $bp-XL) {
          font-size: rem(16);
          line-height: 120%;
          max-width: 205px;
          flex-basis: 50%;
          margin-left: 37px;
          margin-top: 36px;

          &:nth-of-type(1) {
            order: 1;
          }

          &:nth-of-type(2) {
            order: 3;
          }

          &:nth-of-type(3) {
            order: 5;
          }

          &:nth-of-type(4) {
            order: 2;
          }

          &:nth-of-type(5) {
            order: 4;
          }

          &:nth-of-type(6) {
            order: 6;
          }
        }

        @media (min-width: $bp-3XL) {
          margin-left: 71px;
          margin-right: -22px;
          max-width: 190px;
        }

        @media (min-width: $bp-5XL) {
          font-size: rem(20);
          margin-right: 0;
          max-width: 244px;
          flex-basis: 50%;
          margin-left: 37px;
          padding-bottom: 30px;
        }
      }
    }

    &__image {
      img {
        max-width: 672px;
        width: 100%;

        @media (min-width: $bp-XL) {
          max-width: 480px;
        }

        @media (min-width: $bp-3XL) {
          max-width: 528px;
        }

        @media (min-width: $bp-5XL) {
          max-width: 672px;
        }
      }
    }

    &__svg-container {
      width: 100%;
    }

    &__svg-content {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      @media (min-width: $bp-M) {
        width: 86%;
      }
    }
  }

  .clients {
    &__container {
      padding: 81px 0 90px;
      max-width: 768px;
      margin: 0 auto;

      @media (min-width: $bp-XL) {
        height: 100%;
        margin-left: 0;
        max-width: 1050px;
        padding: 15px 0 90px 20px;
      }

      @media (min-width: $bp-5XL) {
        padding: 15px 0 90px 48px;
      }
    }

    &__title {
      margin-bottom: -20px;
      padding: 0 15px;

      @media (min-width: $bp-XL) {
        margin-bottom: -25px;
      }

      @media (min-width: $bp-5XL) {
        margin-bottom: 42px;
      }
    }

    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 670px;
      margin: 0 auto;

      @media (min-width: $bp-M) {
        max-width: none;
      }

      .list-block {
        flex-basis: 171px;
        border: 1px solid $color-1;
        border-radius: 16px;
        padding: 16px;
        min-height: 171px;
        max-width: 171px;
        display: flex;
        align-items: center;
        justify-content: center;

        & {
          margin-right: -1px;
        }

        &:first-of-type {
          margin-left: 15px;
        }

        &:last-of-type {
          margin-right: 15px;
        }

        @media (min-width: $bp-M) {
          flex-basis: 171px;
          max-width: none;
          min-height: 171px;
        }

        @media (min-width: $bp-XL) {
          min-height: 160px;
          min-width: 160px;
          max-width: 160px;
          flex-basis: 160px;
          border-radius: 24px;
          padding: 16px 24px 24px;
        }

        @media (min-width: $bp-3XL) {
          min-height: 192px;
          min-width: 192px;
          max-width: 192px;
          flex-basis: 192px;
          padding: 16px 24px 24px;
        }

        @media (min-width: $bp-5XL) {
          min-height: 288px;
          min-width: 288px;
          width: 288px;
          flex-basis: 288px;
        }

        &__image {
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 32px;

            @media (min-width: $bp-5XL) {
              max-height: 42px;
            }
          }
        }
      }
    }

    .swiper-wrapper {
      padding-top: 43px;

      @media (min-width: $bp-XL) {
        padding-top: 65px;
      }

      @media (min-width: $bp-5XL) {
        padding-top: 43px;
      }
    }

    .swiper-prev {
      position: absolute;
      content: "";
      right: 69px;
      top: 0;
      width: 20px;
      height: 15px;
      background-image: url("../images/arrow-blue.svg");
      background-size: inherit;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      transform-origin: center;
      z-index: 3;
      cursor: pointer;

      @media (min-width: $bp-XL) {
        right: 100px;
      }

      @media (min-width: $bp-3XL) {
        right: 100px;
        background-size: cover;
        height: 18px;
        width: 18px;
      }
    }

    .swiper-next {
      position: absolute;
      content: "";
      right: 15px;
      top: 0;
      width: 20px;
      height: 15px;
      background-image: url("../images/arrow-blue.svg");
      background-size: inherit;
      background-repeat: no-repeat;
      z-index: 3;
      cursor: pointer;

      @media (min-width: $bp-XL) {
        right: 46px;
      }

      @media (min-width: $bp-3XL) {
        right: 46px;
        background-size: cover;
        height: 18px;
        width: 18px;
      }
    }

    .swiper-button-disabled {
      filter: grayscale(1);
      opacity: 0.4;
    }
  }

  .testimonials {
    padding: 0;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media (min-width: $bp-XL) {
      width: 50%;
    }

    &__container {
      overflow: hidden;
      max-width: 768px;
      margin: 0 auto 40px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @media (min-width: $bp-XL) {
        padding: 210px 0 90px;
        max-width: 960px;
        margin-left: 0;
      }
    }

    &__title {
      padding: 0 15px;

      @media (min-width: $bp-XL) {
        padding: 0 40px;
        margin-bottom: 42px;
      }
    }

    .slider {
      position: relative;
      overflow: hidden;

      .pattern-top {
        position: absolute;
        width: 100%;
        height: 100%;

        &::before {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          height: 1px;
          width: 100%;
          background-color: $color-1;
          z-index: 2;
        }

        &::after {
          position: absolute;
          content: "";
          left: 0;
          bottom: 42px;
          height: 1px;
          width: 100%;
          background-color: $color-1;
          z-index: 2;
        }
      }

      .pattern-top-white {
        position: absolute;
        width: 100%;
        height: 100%;

        &::before {
          position: absolute;
          content: "";
          left: 24px;
          top: 0;
          height: 1px;
          width: 33px;
          background-color: $color-2;
          z-index: 3;

          @media (min-width: $bp-XL) {
            height: 33px;
            left: 64px;
            top: -14px;
          }

          @media (min-width: $bp-3XL) {
            left: 78px;
          }

          @media (min-width: $bp-5XL) {
            left: 124px;
          }
        }

        &::after {
          position: absolute;
          content: "";
          right: 24px;
          top: 0;
          height: 1px;
          width: 33px;
          background-color: $color-2;
          z-index: 3;

          @media (min-width: $bp-XL) {
            height: 33px;
            right: 64px;
            top: -14px;
          }

          @media (min-width: $bp-3XL) {
            right: 78px;
          }

          @media (min-width: $bp-5XL) {
            right: 124px;
          }
        }
      }

      .pattern-side {
        position: absolute;
        width: 100%;
        height: 100%;

        &::before {
          position: absolute;
          content: "";
          left: 40px;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: $color-1;

          @media (min-width: $bp-XL) {
            left: 80px;
          }

          @media (min-width: $bp-3XL) {
            left: 95px;
          }

          @media (min-width: $bp-5XL) {
            left: 140px;
          }
        }

        &::after {
          position: absolute;
          content: "";
          right: 40px;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: $color-1;

          @media (min-width: $bp-XL) {
            right: 80px;
          }

          @media (min-width: $bp-3XL) {
            right: 95px;
          }

          @media (min-width: $bp-5XL) {
            right: 140px;
          }
        }
      }

      .pattern-bottom-white {
        position: absolute;
        width: 100%;
        height: 100%;

        &::before {
          position: absolute;
          content: "";
          left: 24px;
          bottom: 27px;
          height: 33px;
          width: 33px;
          z-index: 3;
          background-color: $color-2;

          @media (min-width: $bp-XL) {
            left: 64px;
          }

          @media (min-width: $bp-3XL) {
            left: 78px;
          }

          @media (min-width: $bp-5XL) {
            left: 124px;
          }
        }

        &::after {
          position: absolute;
          content: "";
          right: 24px;
          bottom: 27px;
          height: 33px;
          width: 33px;
          z-index: 3;
          background-color: $color-2;

          @media (min-width: $bp-XL) {
            right: 64px;
          }

          @media (min-width: $bp-3XL) {
            right: 78px;
          }

          @media (min-width: $bp-5XL) {
            right: 124px;
          }
        }
      }

      .swiper {
        font-family: $baseFontMono;
        text-align: center;
        margin: 0 -40px;
        padding: 0 75px 42px;
        position: relative;

        @media (min-width: $bp-3XL) {
          padding: 0 210px 42px;
          margin: 0 -80px;
        }

        @media (min-width: $bp-5XL) {
          padding: 0 240px 42px;
        }

        &::before {
          position: absolute;
          content: "";
          top: 0;
          left: 40px;
          width: 40px;
          height: 100%;
          background: $color-2;
          z-index: 2;

          @media (min-width: $bp-XL) {
            width: 80px;
          }

          @media (min-width: $bp-3XL) {
            width: 97px;
            left: 78px;
          }

          @media (min-width: $bp-5XL) {
            left: 78px;
            width: 141px;
          }
        }

        &::after {
          position: absolute;
          content: "";
          top: 0;
          right: 40px;
          width: 40px;
          height: 100%;
          background: $color-2;
          z-index: 2;

          @media (min-width: $bp-XL) {
            width: 80px;
          }

          @media (min-width: $bp-3XL) {
            width: 97px;
            right: 78px;
          }

          @media (min-width: $bp-5XL) {
            right: 78px;
            width: 141px;
          }
        }

        &-wrapper {
          margin: 0 auto;
        }

        &-pagination {
          font-size: rem(12);
          line-height: 120%;
          color: $color-3;
          bottom: 5px;
          z-index: 1;

          @media (min-width: $bp-XL) {
            font-size: rem(16);
            bottom: 0;
          }

          @media (min-width: $bp-5XL) {
            font-size: rem(20);
            line-height: 120%;
          }
        }

        &-slide {
          height: auto !important;
          display: flex;
          flex-direction: column;

          &__text {
            padding: 76px 20px 71px;
            margin: 0;
            flex: 1;

            @media (min-width: $bp-XL) {
              font-size: rem(20);
              line-height: rem(24);
              letter-spacing: 0;
              padding: 165px 80px 156px;
            }

            @media (min-width: $bp-3XL) {
              padding: 186px 50px 182px;
            }

            @media (min-width: $bp-5XL) {
              padding: 220px 20px 211px;
            }
          }

          &__author {
            font-size: rem(12);
            line-height: 120%;
            margin: 0;
          }

          &__position {
            font-size: rem(12);
            line-height: 120%;
            margin: 0;
            color: $color-3;
            padding-bottom: 15px;

            @media (min-width: $bp-5XL) {
              padding-bottom: 20px;
            }
          }
        }

        .swiper-prev {
          position: absolute;
          content: "";
          left: 46px;
          bottom: 6px;
          width: 20px;
          height: 15px;
          background-image: url("../images/arrow-blue.svg");
          background-size: inherit;
          background-repeat: no-repeat;
          transform: rotate(180deg);
          transform-origin: center;
          z-index: 3;
          cursor: pointer;

          @media (min-width: $bp-XL) {
            left: 68px;
            bottom: 3px;
          }

          @media (min-width: $bp-3XL) {
            left: 114px;
          }

          @media (min-width: $bp-5XL) {
            left: 128px;
            background-size: cover;
            height: 18px;
            width: 18px;
            bottom: 2px;
          }
        }

        .swiper-next {
          position: absolute;
          content: "";
          right: 46px;
          bottom: 6px;
          width: 20px;
          height: 15px;
          background-image: url("../images/arrow-blue.svg");
          background-size: inherit;
          background-repeat: no-repeat;
          z-index: 3;
          cursor: pointer;

          @media (min-width: $bp-XL) {
            right: 68px;
            bottom: 3px;
          }

          @media (min-width: $bp-3XL) {
            right: 114px;
          }

          @media (min-width: $bp-5XL) {
            right: 128px;
            background-size: cover;
            height: 18px;
            width: 18px;
            bottom: 2px;
          }
        }

        .swiper-pagination {
          position: absolute;
          width: 100%;
          left: 0;
          margin-bottom: 1px;
          line-height: 15px;
        }

        .swiper-button-disabled {
          filter: grayscale(1);
          opacity: 0.4;
        }
      }
    }
  }

  .why {
    &__container {
      padding: 46px 15px 12px;
      max-width: 768px;
      margin: 0 auto;

      @media (min-width: $bp-XL) {
        padding: 0 40px 169px 48px;
        max-width: 960px;
        margin: 0 auto 0 0;
      }

      @media (min-width: $bp-3XL) {
        padding: 0 48px 169px;
      }
    }

    @media (min-width: $bp-XL) {
      padding-top: 12px;
    }

    &__title {
      margin-bottom: 9px;

      @media (min-width: $bp-XL) {
        margin-bottom: 55px;
      }
    }

    &__list {
      .list-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media (min-width: $bp-XL) {
          position: relative;

          &::before {
            position: absolute;
            content: "";
            width: 48.1%;
            top: 3px;
            right: 0;
            height: 1px;
            background: $color-1;
          }

          &::after {
            position: absolute;
            content: "";
            width: 1px;
            right: 0;
            left: 50%;
            top: 50%;
            transform: translateY(-50%);
            height: calc(100% - 40px);
            background: $color-1;
          }
        }

        &__text {
          font-family: $baseFontMono;

          @media (min-width: $bp-XL) {
            width: 275px;
          }

          @media (min-width: $bp-3XL) {
            width: 290px;
          }
        }

        &__number {
          font-family: $numberFont;
          font-size: rem(80);
          line-height: rem(80);
          color: $color-3;
          margin: 21px 0 0 0;
          width: 50%;
          position: relative;

          @media (min-width: $bp-SM) {
            text-align: center;
          }

          @media (min-width: $bp-XL) {
            text-align: left;
            font-size: rem(136);
            line-height: rem(136);
            margin: 34px 0 0 0;
          }

          &::before {
            position: absolute;
            content: "";
            width: 100%;
            bottom: -7px;
            left: 0;
            height: 1px;
            background: $color-1;

            @media (min-width: $bp-XL) {
              display: none;
            }
          }

          &::after {
            position: absolute;
            content: "";
            width: 1px;
            bottom: 11px;
            right: 0;
            height: calc(100% + 7px);
            background: $color-1;

            @media (min-width: $bp-XL) {
              display: none;
            }
          }
        }

        &:nth-of-type(even) {
          @media (min-width: $bp-XL) {
            &::before {
              position: absolute;
              content: "";
              width: 48.1%;
              top: -2px;
              right: initial;
              left: 0;
              height: 1px;
              background: $color-1;
            }
          }

          .list-item__number {
            margin-left: auto;
            text-align: right;

            @media (min-width: $bp-SM) {
              text-align: center;
            }

            @media (min-width: $bp-XL) {
              text-align: right;
            }

            @media (min-width: $bp-4XL) {
              text-align: left;
              margin-left: 55.4%;
            }

            &::after {
              position: absolute;
              content: "";
              width: 1px;
              bottom: 11px;
              left: 0;
              right: initial;
              height: calc(100% + 7px);
              background: $color-1;
            }
          }

          .list-item__text {
            @media (min-width: $bp-XL) {
              margin-left: 55.4%;
              margin-top: -11px;
            }
          }
        }

        @media (min-width: $bp-XL) {
          &:nth-of-type(odd) {
            .list-item__text {
              margin-top: -10px;
            }
          }
        }

        @media (min-width: $bp-4XL) {
          &:nth-of-type(odd) {
            .list-item__text {
              margin-left: 6%;
            }

            .list-item__number {
              margin-left: 5.5%;
            }
          }
        }

        @media (min-width: 1800px) {
          &:nth-of-type(odd) {
            .list-item__text {
              margin-left: 11%;
            }

            .list-item__number {
              margin-left: 10.5%;
            }
          }
        }

        &:last-of-type {
          .list-item__number {
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }

  .projects {
    &__container {
      padding: 76px 15px 70px;
      max-width: 768px;
      margin: 0 auto;

      @media (min-width: $bp-M) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media (min-width: $bp-XL) {
        padding: 44px 40px 190px;
        max-width: 960px;
        margin: 0 auto 0 0;
      }

      @media (min-width: $bp-3XL) {
        padding: 44px 48px 169px;
      }
    }

    &__title {
      margin-bottom: 28px;

      span {
        display: block;
        text-indent: 72px;

        @media (min-width: $bp-XL) {
          text-indent: 143px;
        }

        @media (min-width: $bp-5XL) {
          text-indent: 189px;
        }
      }
    }

    &__list {
      @media (min-width: $bp-SM) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @media (min-width: $bp-4XL) {
        justify-content: flex-end;
      }

      .list-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 11px 0 18px;
        position: relative;

        @media (min-width: $bp-SM) {
          flex-basis: 48%;

          &:first-of-type {
            flex-basis: 100%;

            &::after {
              position: absolute;
              content: "";
              width: 100%;
              height: 1px;
              left: 0;
              bottom: 2px;
              background: $color-1;
            }
          }

          &:not(:first-of-type) {
            &::before {
              display: none;
            }
          }

          &:nth-of-type(3) {
            &::after {
              position: absolute;
              content: "";
              width: 1px;
              height: calc(100% - 33px);
              left: -4.2%;
              bottom: 20px;
              background: $color-1;
            }
          }
        }

        @media (min-width: $bp-4XL) {
          &:first-of-type {
            .list-block__container {
              margin-left: auto;
              padding-left: 143px;
            }
          }

          &:not(:first-of-type) {
            max-width: 360px;
            margin-left: 32px;
            flex-basis: 38.5%;
          }

          &:nth-of-type(2) {
            margin-left: 0;
          }

          &:nth-of-type(3) {
            &::after {
              left: -15px;
            }
          }
        }

        @media (min-width: 1750px) {
          &:not(:first-of-type) {
            flex-basis: 39.2%;
          }
        }

        @media (min-width: 1800px) {
          &:not(:first-of-type) {
            flex-basis: 39.5%;
          }
        }

        @media (min-width: 1850px) {
          &:not(:first-of-type) {
            flex-basis: 39.8%;
          }
        }

        @media (min-width: 1900px) {
          &:not(:first-of-type) {
            flex-basis: 39.9%;
          }
        }

        @media (min-width: $bp-5XL) {
          &:first-of-type {
            .list-block__container {
              padding-left: 189px;
            }
          }

          &:not(:first-of-type) {
            flex-basis: 322px;
          }
        }

        &__container {
          width: 100%;
          display: flex;
          flex-direction: column;

          p,
          a {
            color: $color-3;
            font-size: rem(14);
            line-height: 100%;
            margin-bottom: 16px;
            font-family: $baseFontMono;
            text-decoration: underline;

            @media (min-width: $bp-XL) {
              font-size: rem(20);
            }
          }

          img {
            width: 100%;
          }
        }

        .desktop {
          display: none;

          @media (min-width: $bp-M) {
            display: block;
          }
        }

        .mobile {
          @media (min-width: $bp-M) {
            display: none;
          }
        }

        &::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 1px;
          left: 0;
          top: -3px;
          background: $color-1;
        }
      }
    }
  }

  .form {
    background: $color-3;
    border-left: 1px solid $color-2;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    @media (min-width: $bp-XL) {
      min-height: 100vh;
    }

    &__container {
      padding: 17px 15px 12px;
      max-width: 768px;
      margin: 0 auto;

      @media (min-width: $bp-XL) {
        padding: 14px 40px 12px;
        max-width: 890px;
        margin: 0 auto 0 0;
      }

      @media (min-width: $bp-3XL) {
        padding: 14px 48px 12px;
      }
    }

    &__title {
      margin-bottom: 93px;
      color: $color-2;

      @media (min-width: $bp-XL) {
        letter-spacing: -2px;
        margin-bottom: 85px;
      }

      @media (min-width: $bp-5XL) {
        margin-bottom: 93px;
      }

      a {
        color: $color-2;
      }
    }

    form {
      display: flex;
      flex-direction: column;

      @media (min-width: $bp-XL) {
        margin-bottom: 0;
      }

      .input-block {
        position: relative;
        max-width: 576px;
        width: 100%;
        margin: 0 auto 37px;

        @media (min-width: $bp-XL) {
          margin-left: 0;
          max-width: 440px;
        }

        @media (min-width: $bp-3XL) {
          max-width: 431px;
        }

        @media (min-width: $bp-5XL) {
          max-width: 576px;
        }

        &.error {
          .error-message {
            display: flex;
          }
        }
      }

      .floating-name {
        position: absolute;
        pointer-events: none;
        left: 0;
        top: 9px;
        transition: 0.2s ease all;
        color: $color-2;
        font-size: rem(16);
        line-height: 120%;
        opacity: 0.64;
        font-family: $baseFontMono;
      }

      .floating-count {
        position: absolute;
        pointer-events: none;
        right: 0;
        top: -7px;
        transition: 0.2s ease all;
        color: $color-2;
        font-size: 11px;
        line-height: 120%;
        opacity: 0.64;
        font-family: $baseFontMono;
        margin: 0;
      }

      .error-message {
        color: $color-6;
        margin: 10px 0 0 0;
        display: none;
        align-items: center;
        font-size: rem(12);
        line-height: 120%;

        img {
          margin-right: 10px;
        }
      }

      .is-active {
        top: -7px;
        font-size: 11px;
      }

      .textarea {
        min-height: 42px;
        height: 42px;
        display: inline-table;
        width: 100%;

        .floating-name {
          top: 6px;
        }
      }

      .textarea .is-active {
        top: -9px;
        font-size: 11px;
      }

      input:not([type="submit"]),
      textarea {
        width: 100%;
        background: transparent;
        border: none;
        border-bottom: 1px solid $color-2;
        font-size: rem(16);
        line-height: 120%;
        color: $color-2;
        font-family: $baseFontMono;
        resize: none;
        cursor: pointer;
        -webkit-appearance: none;
        -webkit-border-radius: none;
        border-radius: 0;
        -webkit-padding-start: 0;
      }

      input:not([type="submit"]) {
        height: 35px;
        min-height: 35px;
      }

      textarea {
        padding: 5px 0 8px 0;
        display: block;
      }

      input[type="submit"] {
        background: $color-1;
        color: $color-2;
        height: 80px;
        width: 100%;
        opacity: 1;
        border: none;
        text-decoration: underline;
        font-size: rem(14);
        line-height: 100%;
        max-width: 576px;
        margin: 0 auto;
        cursor: pointer;
        transition: $transition-ease;
        -webkit-appearance: none;
        -webkit-border-radius: none;
        border-radius: 0;

        @media (min-width: $bp-XL) {
          margin-left: 0;
          max-width: 440px;

          &:hover {
            background: $color-4;
          }
        }

        @media (min-width: $bp-3XL) {
          max-width: 431px;
        }

        @media (min-width: $bp-5XL) {
          max-width: 576px;
          font-size: rem(20);
        }
      }
    }

    .socials {
      padding: 17px 15px 24px;
      max-width: 768px;
      margin: 0 0 0 auto;
      display: flex;
      justify-content: flex-end;

      @media (min-width: $bp-XL) {
        padding: 84px 48px 24px;
        width: 100%;
        max-width: 100%;
        margin: 0;
      }

      &__item {
        margin-left: 24px;
        width: 40px;
        height: 40px;
        overflow: hidden;
        background: $color-2;
        border-radius: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          transition: $transition-ease;
          max-height: 25px;
        }

        @media (min-width: $bp-XL) {
          transition: $transition-ease;
          width: 48px;
          height: 48px;

          img {
            max-height: 30px;
          }

          &:hover {
            img {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }

  .swiper-prev,
  .swiper-next {
    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }
}

.scrollbar-thumb {
  width: 2px !important;
  background: $color-1 !important;
  left: 0 !important;
  border-radius: 0 !important;
  opacity: 1 !important;
  top: 0 !important;
  bottom: 0 !important;
}

.scrollbar-track-y {
  opacity: 1 !important;
  width: 2px !important;
}

.scrollbar-hide {
  display: none !important;
}

@media (min-width: $bp-XL) {
  .scrollbar-track-y {
    left: 50%;
  }
}

.menu-open {
  .simplebar-vertical {
    opacity: 0;
  }
}
