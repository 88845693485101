//global styles

html,
body {
  height: 100%;
  background-color: $color-2;
  font-family: $baseFont;
  color: $color-1;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth !important;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

input,
textarea,
select,
button {
  outline: none;
}

input:active,
textarea:active,
select:active,
button:active {
  outline: none;
}

button:focus {
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  outline: none;
  transition: $transition-ease;

  &:hover {
    text-decoration: none;
  }
}

::selection {
  background: $color-1;
  color: $color-2;
  text-shadow: none;
}
